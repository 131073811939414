import React, { useRef } from "react";

import { useEffect } from "react";
import { useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import DocumentHeader from "../../../components/document-header/document-header.componenet";
import DocumentBody from "../../../components/document-body/document-body.componenet";
import { SALES_INVOICES_COLUMNS_DOCUMENT } from "./sales-invoice-columns-document.data";
import useFetchDocument from "../../../effects/use-fetch-document";
import { withRouter } from "react-router-dom";
import { authTokenSelector, selectSettings } from "../../../redux/user/user.reselect";
import { selectIsRefresh } from "../../../redux/refresh/refresh.reselect";
import { getUserSettings } from "../../../utils/auth_token.utils";

const SalesOrderDocument = ({
    authTokenFromState,
    match,
    isRefresh,
    settings
}) => {
    const contentRef = useRef(null);
    const title = "Sales Invoice"
    const path = "/salesorders";
    const subPath = "/invoices/doucments"

    const endpoint = `${path}/${match.params.uuid}${subPath}`;
    const { data, error, isLoading } = useFetchDocument(
        endpoint,
        authTokenFromState,
        isRefresh
    );

    const [dataIsReady, setDataIsReady] = useState(false);
    const [dataForDoucment, setDataForDoucment] = useState(null);
    useEffect(() => {
        setDataForDoucment({
            header: [
                data.company,
                data.salesOrderSummary
            ],
            body: {
                sections: [
                    data.bankAccountSummary,
                    data.customerWithBillingAddressSummary,
                    data.customerWithShippingAddressSummary,
                    data.customerSummary,
                    data.shippingMethodSummary
                ],
                table: data.items,
                total: data.total,
                totalBalance: data.totalBalance,
            }
        })
        setDataIsReady(true)
    }, [data])

    const [salesInvoiceColumnsDoc, setSalesInvoiceColumnsDoc] = useState(SALES_INVOICES_COLUMNS_DOCUMENT);
    const [isSalesInvoiceColumnDocSet, setIsSalesInvoiceColumnDocSet] = useState(false);

    // useEffect(() => {
    //     const setting = getUserSettings(settings);
    //     if (setting && !setting.settings.length && isSalesInvoiceColumnDocSet === false) {    
        
    //         if (Object.keys(SALES_INVOICES_COLUMNS_DOCUMENT).length) {
    //             SALES_INVOICES_COLUMNS_DOCUMENT.splice(3, 1);
    //         }
    
    //         setIsSalesInvoiceColumnDocSet(true);
    //         setSalesInvoiceColumnsDoc(SALES_INVOICES_COLUMNS_DOCUMENT);
    //     } else {
    //         setIsSalesInvoiceColumnDocSet(true);
    //         setSalesInvoiceColumnsDoc(SALES_INVOICES_COLUMNS_DOCUMENT);
    //     }
    // }, [salesInvoiceColumnsDoc]);

    useEffect(() => {
        const setting = getUserSettings(settings);
    
        if (setting && !setting.settings.length && !isSalesInvoiceColumnDocSet) {    
            const updatedColumnsDoc = [...SALES_INVOICES_COLUMNS_DOCUMENT];
            
            if (Object.keys(updatedColumnsDoc).length > 3) {
                updatedColumnsDoc.splice(3, 1);
            }
    
            setIsSalesInvoiceColumnDocSet(true);
            setSalesInvoiceColumnsDoc(updatedColumnsDoc);
        } else if (!isSalesInvoiceColumnDocSet) {
            setIsSalesInvoiceColumnDocSet(true);
            setSalesInvoiceColumnsDoc([...SALES_INVOICES_COLUMNS_DOCUMENT]);
        }
    }, [settings, isSalesInvoiceColumnDocSet]);

    return (
        <>{dataIsReady && isSalesInvoiceColumnDocSet &&
            <>
                <DocumentHeader
                    title={title}
                    contentRef={contentRef}
                />
                <DocumentBody
                    title={title}
                    endpoint={endpoint}
                    contentRef={contentRef}
                    data={dataForDoucment}
                    tableColumns={salesInvoiceColumnsDoc}
                    tableData={data.items}
                    error={error}
                    isLoading={isLoading}
                    totalBalance={data.totalBalance}
                    watermark={true}
                />
            </>
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    authTokenFromState: authTokenSelector,
    isRefresh: selectIsRefresh,
    settings: selectSettings
});

export default withRouter(connect(mapStateToProps, null)(SalesOrderDocument));
