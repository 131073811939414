import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const INVENTORY_REPORT_COST_SUMMARY_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/inventorycostsummary",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  hasDateRange: true,
  tableHasGrandTotal: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const INVENTORY_REPORT_COST_SUMMARY_COLUMN_TEMPLATE = [
  {
    Header: "Details",
    accessor: "details",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Cost",
    accessor: "cost",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Cost: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  }
]
