import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToTwoDecimal } from '../../utils/format-number.util';

export const INVENTORY_REPORT_ITEM_LIST_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/itemlist",
  listPage: "/inventory-reports",
  pagePath:"items",
  hasChart: false,
  tableHasGrandTotal: false,
  hasStockFilter: true,
  hasLocationFilter: true,
  hasSearch: true
}

export const INVENTORY_REPORT_ITEM_LIST_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Qty on Hand",
    accessor: "qtyOnHand",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      if (!value) {
          return displayCellValue(value);
      }

      value.label = value && value.label && isNaN(value.label) ?
          value.label :
          !isMobileView ? formatNumberToTwoDecimal(value.label) : `${formatNumberToTwoDecimal(value.label)} Quantity`


      return (
          <div className={isMobileView && isRightAligned ?
              'list-table__body__row__cell--right-aligned-mobile-cell' : ''}>
              <TableCellStatus
                  value={value}
                  noDot={true}
              />
          </div>
      );
    }
  },
  {
    Header: "Available",
    accessor: "available",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Available: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }
  },
  {
    Header: "Unit Price (ETB)",
    accessor: "unitPrice",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
        return `Unit Price: ${value ? displayCellValueNumberToTwoDecimal(value) : 0}`;
      }

      return value ? displayCellValueNumberToTwoDecimal(value) : 0;
    }, 
  },
  
]
